.hero-section {
  display: flex;
  width: 50%;
  position: relative;
  justify-content: center;
  height: 100%;
  ming-height: 40rem;
  background-color: black;
}

.hero-image {
  width: 100%;
  height: 46rem;
  object-fit: cover;
  object-position: right;
}
.text-white {
  color: white;
}

.hero-content {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  max-width: 40rem;
}

.hero-title {
  font-size: 1.875rem;
  letter-spacing: 0.05rem;
}

.hero-subtitle {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  font-size: 1.35rem;
}

.hero-button {
  background-color: #2dc5f2;
  width: 190px;
  margin: 0 auto;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  height: 43px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.hero-button:hover {
  background-color: #30b7e3;
  cursor: pointer;
}

#dynamic-title {
  color: #30b7e3;
  font-weight: bold;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: 0.5;
}

.opacity-20 {
  opacity: 0.3;
}

.transition-opacity {
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 600px) {
  .hero-section {
    width: 100%;
  }
}
